<template>
  <div class="d-flex align-end">
      <rating-badge
        v-if="$vuetify.breakpoint.mdAndUp"
        :player="player"
        :view="view"
        @show-all="$emit('show-all')"
      ></rating-badge>
      <v-btn
        color="color3 color3Text--text"
        class="ma-0 mr-1"
        fab small
        @click.stop="show('e')"
      >
        <v-icon small>fas fa-envelope</v-icon>
      </v-btn>
      <v-btn
        color="color3 color3Text--text"
        class="ma-0 mr-1"
        fab small
        @click.stop="show('p')"
      >
        <v-icon small>fas fa-phone</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <!-- Social -->
      <template>
        <v-btn
          v-if="player.social.youtube"
          target="_blank"
          class="ma-0 mr-1"
          fab small
          color="red"
        >
          <v-icon color="white">fab fa-youtube</v-icon>
        </v-btn>
        <v-btn
          v-if="player.social.facebook"
          target="_blank"
          class="ma-0 mr-1"
          fab small
          color="#3B5998"
          :href="facebook"
        >
          <v-icon color="white">fab fa-facebook-f</v-icon>
        </v-btn>

        <v-btn
          v-if="instagram"
          target="_blank"
          class="ma-0 mr-1 instagram"
          fab small
          :href="instagram"
        >
          <v-icon color="white">fab fa-instagram</v-icon>
        </v-btn>

        <v-btn
          v-if="twitter"
          target="_blank"
          class="ma-0 mr-1"
          fab small
          color="#1DA1F2"
          :href="twitter"
        >
          <v-icon color="white">fab fa-twitter</v-icon>
        </v-btn>

        <v-btn
          v-if="snapchat"
          target="_blank"
          class="ma-0 mr-1"
          fab small
          color="#FFFC00"
          :href="snapchat"
        >
          <v-icon color="white">fab fa-snapchat-ghost</v-icon>
        </v-btn>

        <v-btn
          v-if="tiktok"
          target="_blank"
          class="ma-0 mr-1"
          fab small
          :href="tiktok"
        >
          <v-avatar
            :size="40"
          >
            <img src="https://vblimages.blob.core.windows.net/images/385a8a56-c788-49f4-a8e9-58ab3ba9285a.png" alt="tiktok">
          </v-avatar>
        </v-btn>
      </template>
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <loading-bar :value="loading"></loading-bar>
          <v-card-text v-if="!user">
            <got-blocked>
              <v-alert type="error" :value="true">
                You must be signed in to view contact info.
              </v-alert>
            </got-blocked>
          </v-card-text>
          <v-card-text v-else class="subheading">
            <div v-if="view.vbl" class="xsmall">{{(showing === 'p' ? jProps.phone : jProps.email) | formatArray}}</div>
            <v-expand-transition>
              <div v-if="showing && !loading">
                <div v-if="showing === 'p'">
                  <div v-if="contacts && contacts.phone">
                    {{player.fullname}}'s Phone: <a :href="`tel:${contacts.phone}`">{{contacts.phone | usPhone}}</a>
                  </div>
                  <div v-else>
                    We are sorry, {{player.firstName}}'s phone number is not available.
                  </div>
                </div>
                <div v-if="showing === 'e'">
                  <div v-if="contacts && contacts.email">
                    {{player.fullname}}'s Email: <a :href="`mailto:${contacts.email}`">{{contacts.email}}</a>
                  </div>
                  <div v-else>
                    We are sorry, {{player.firstName}}'s email is not available.
                  </div>
                </div>
              </div>
            </v-expand-transition>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="color3" text @click.stop="dialog = false">Got it</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const RatingBadge = () => import('@/components/Player/RatingBadge')

export default {
  props: ['player', 'view'],
  data () {
    return {
      dialog: false,
      loading: false,
      contacts: null,
      showing: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    isPlayer () {
      return this.user && this.user.isPlayer
    },
    jProps () {
      return this.player && this.player.jProps.settings
    },
    showPhone () {
      return this.view.admin || (this.isPlayer && this.jProps && this.jProps.phone && this.jProps.phone.includes('players'))
    },
    showEmail () {
      return this.view.admin || (this.isPlayer && this.jProps && this.jProps.email && this.jProps.email.includes('players'))
    },
    social () {
      return this.player && this.player.jProps.social
    },
    facebook () {
      let handle = this.player.social.facebook
      if (handle) {
        if (handle.includes('/')) {
          while (handle.endsWith('/')) {
            handle = handle.slice(0, -1)
          }
          handle = handle.substr(handle.lastIndexOf('/') + 1)
        }
        return `https://www.facebook.com/${handle}`
      }
      return null
    },
    instagram () {
      let handle = this.player.social.instagram
      if (handle) {
        if (handle.includes('/')) {
          while (handle.endsWith('/')) {
            handle = handle.slice(0, -1)
          }
          handle = handle.substr(handle.lastIndexOf('/') + 1)
        }
        handle = handle.replace('@', '')
        return `https://www.instagram.com/${handle}`
      }
      return null
    },
    twitter () {
      let handle = this.player.social.twitter
      if (handle) {
        if (handle.includes('/')) {
          while (handle.endsWith('/')) {
            handle = handle.slice(0, -1)
          }
          handle = handle.substr(handle.lastIndexOf('/') + 1)
        }
        return `https://twitter.com/${handle}`
      }
      return null
    },
    snapchat () {
      let handle = this.player.social.snapchat
      if (handle) {
        if (handle.includes('/')) {
          while (handle.endsWith('/')) {
            handle = handle.slice(0, -1)
          }
          handle = handle.substr(handle.lastIndexOf('/') + 1)
        }
        return `https://www.snapchat.com/add/${handle}`
      }
      return null
    },
    tiktok () {
      return this.player.social.tiktok
    }
  },
  methods: {
    show (what) {
      this.showing = what
      if (this.user && !this.contacts) this.loadContact()
      this.dialog = true
    },
    loadContact () {
      this.loading = true
      this.$VBL.player.getContacts(this.player.id)
        .then(r => {
          this.contacts = r.data
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.showing = null
      }
    },
    user: function (n, o) {
      this.contacts = null
    }
  },
  components: {
    RatingBadge
  }
}
</script>

<style scoped>
.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%, #285AEB 90%)
}
</style>
